import { render, staticRenderFns } from "./companyAdvertisement.vue?vue&type=template&id=4055c87a&scoped=true"
import script from "./companyAdvertisement.vue?vue&type=script&lang=js"
export * from "./companyAdvertisement.vue?vue&type=script&lang=js"
import style0 from "./companyAdvertisement.vue?vue&type=style&index=0&id=4055c87a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4055c87a",
  null
  
)

export default component.exports