<template>
  <div class="videoPlayComponents">
    <div>
      <slot></slot>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="868px"
      class="videoPlayDialog"
      :modal="showModal"
      :destroy-on-close="true"
      append-to-body
    >
      <div style="height: 500px">
        <div v-if="dialogVisible" id="J_prismPlayer" style="height: 100%"></div>
      </div>
    </el-dialog>

   
  </div>
</template>


<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    perviewTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      videoId: "",
      pre_view_time: 0,
    };
  },
  mounted() {
    if (this.PJSource == 13) {
      this.pre_view_time = 180;
    }
  },
  methods: {
    async handlePlay() {
      this.dialogVisible = true;
      // var videoAdClose = function (videoAd) {
      //   videoAd.pauseVideoAd();
      //   videoAd.closeVideoAd();
      // };
      try {
        let result = await this.$store.dispatch("baseStore/getPlayAuth", {
          aliyun_id: this.videoId,
        });
        if (result.success) {
          var player = new Aliplayer(
            {
              id: "J_prismPlayer",
              width: "100%",
              height: "100%",
              autoplay: true,
              language: this.IS_EN ? "en-us" : "zh-cn",
              vid: result.data.VideoMeta.VideoId,
              playauth: result.data.PlayAuth,
              cover: result.data.VideoMeta.CoverURL,
              
            },
            (player) => {
              // let eventDoms = document.querySelectorAll("[data-click]");
              // eventDoms.forEach((item) => {
              //   item.addEventListener("click", () => {
              //     if (item.getAttribute("data-method") == "closePreviewTip") {
              //       player
              //         .getComponent("PreviewVodComponent")
              //         .closePreviewLayer();
              //     } else {
              //       this.$emit(item.getAttribute("data-method"));
              //     }
              //   });
              // });
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    startPlay(videoId) {
      // if (JSON.stringify(this.USER_INFO) == "{}") {
      //   this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      //   if (this.PJSource == 10 || this.PJSource == 11) {
      //     this.$router.push("/sign");
      //   }
      //   return;
      // }
      this.videoId = videoId;
      this.handlePlay();
    },
  },
};
</script>

<style lang="less" scoped>
.videoPlayComponents {
  /deep/.videoPlayDialog {
    z-index: 3300 !important;
    // .el-dialog__body {
    //   padding: 0px;
    //   .playbox {
    //     height: 500px;
    //     .prism-player {
    //       height: 100%;
    //     }
    //   }
    // }
  }
}
/deep/.el-dialog__body {
  padding: 0px;
}
</style>
<style lang="less">
.scbacontacttips {
  color: #00c1de;
  margin-left: 2px;
}
.endPreviewTemplate {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tips {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .scbaContactInfo {
    margin: 0 auto;
    width: 500px;
    height: 100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .concatItem {
      width: 230px;
      font-size: 14px;
      color: #fff;
    }
  }
  .knowBtn {
    width: 120px;
    padding: 7px 0px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    margin: 0 auto;
    margin-top: 15px;
    cursor: pointer;
  }
}
</style>
<style  lang='less'>
.video-ad-component .video-ad-detail {
  display: none !important;
}
</style>
